import {
  agency_product_t,
  contractor_info_t,
  contractor_t,
  formik_value_t,
  structure_t,
} from "src/types";
import { FormikOtherInsured } from "src/types/formik";
import { quote_t } from "src/types/http/quote.types";
import { generateFiscalCode, get_language } from "src/utils";

const get_period = (coverage_id: string) => {
  if (coverage_id === "id_daily") return "1";
  if (coverage_id === "id_seasonal") return "2";
  if (coverage_id === "id_period") return "3";
  if (coverage_id === "id_weekly") return "4";
  if (coverage_id === "id_monthly") return "5";
  if (coverage_id === "id_quarterly") return "6";
};

const get_contractor = (values?: formik_value_t): contractor_t => {
  const contractor_: contractor_t = {
    name: values?.insured_name?.trim(),
    surname: values?.insured_surname?.trim(),
    nationality: values?.insured_nationality === "foreign_id" ? "EXT" : "IT",
    ...(values?.insured_cap && {
      cap: values?.insured_cap,
    }),
    ...(values?.insured_vat_number && {
      vatNumber: values?.insured_vat_number,
    }),
    fiscalCode:
      values?.insured_fc_question === "yes_id"
        ? values?.insured_fc?.toUpperCase()
        : generateFiscalCode({
          name: values?.insured_name || "",
          surname: values?.insured_surname || "",
          birthDate: values?.insured_birth || "",
          birthPlace: values?.insured_birth_place || "",
          gender: values?.insured_gender === "male" ? "M" : "F",
        }),
    ...(values?.insured_birth &&
      values?.insured_fc_question === "no_id" && {
      birthDate: values?.insured_birth.split("/").reverse().join("-"),
    }),
    ...(values?.insured_birth_nation &&
      values?.insured_fc_question === "no_id" && {
      birthNation: values?.insured_birth_nation,
    }),
    ...(values?.insured_birth_place &&
      values?.insured_fc_question === "no_id" && {
      birthPlace: values?.insured_birth_place,
    }),
    ...(values?.insured_gender &&
      values?.insured_fc_question === "no_id" && {
      gender: values?.insured_gender === "female" ? "F" : "M",
    }),
    contractor: true,
    mainContact: {
      type: "email",
      value: values?.insured_email?.trim(),
    },
    ...(values?.insured_phone?.formattedPhone && {
      otherContacts: [{ type: "mobile", value: values?.insured_phone?.formattedPhone }],
    }),
    tdConsent: values?.consent_td,
    privacyConsent: values?.consent_privacy,
    ...(values?.consent_marketing && {
      marketingConsent: values?.consent_marketing,
    }),
    ...(values?.consent_profiling && {
      profilingConsent: values?.consent_profiling,
    }),
    ...(values?.consent_communication && {
      communicationConsent: values?.consent_communication,
    }),
  };
  return contractor_;
};

const get_assets = (
  contractors?: FormikOtherInsured[]
): contractor_info_t[] => {
  const assets_: contractor_info_t[] = [];
  contractors?.map((contractor: FormikOtherInsured) =>
    assets_.push({
      name: contractor?.name?.trim(),
      surname: contractor?.surname?.trim(),
      nationality: contractor?.nationality === "foreign_id" ? "EXT" : "IT",
      fiscalCode:
        contractor?.fc_question === "yes_id"
          ? contractor?.fc?.toUpperCase()
          : generateFiscalCode({
            name: contractor?.name || "",
            surname: contractor?.surname || "",
            birthDate: contractor?.birth || "",
            birthPlace: contractor?.birth_place || "",
            gender: contractor?.gender === "male" ? "M" : "F",
          }),
      ...(contractor?.birth &&
        contractor?.fc_question === "no_id" && {
        birthDate: contractor?.birth.split("/").reverse().join("-"),
      }),
      ...(contractor?.birth_nation &&
        contractor?.fc_question === "no_id" && {
        birthNation: contractor?.birth_nation,
      }),
      ...(contractor?.birth_place &&
        contractor?.fc_question === "no_id" && {
        birthPlace: contractor?.birth_place,
      }),
      ...(contractor?.gender &&
        contractor?.fc_question === "no_id" && {
        gender: contractor?.gender === "female" ? "F" : "M",
      }),
    })
  );

  return assets_;
};

const get_structure = (
  convention_id: string,
  structures: structure_t[]
): string => {
  let structure = "";
  structure =
    structures?.find((item) => item?.id === convention_id)?.name?.[
    get_language().toLowerCase()
    ] || "";

  return structure;
};

export const get_quote_data = (
  values?: formik_value_t,
  agency?: agency_product_t,
  token?: string,
  quote_id?: string
): quote_t => {
  const data_: quote_t = {
    agency: {
      id: agency?.id,
      name: agency?.name,
      ...(values?.convention && {
        structure: values?.convention,
      }),
      ...(values?.convention && {
        structureName: get_structure(
          values?.convention,
          agency?.structures || []
        ),
      }),
      isCustomActive: agency?.isCustomActive || false,
      isOriginalPayment:
        agency?.isOriginalPayment === undefined
          ? true
          : agency?.isOriginalPayment,
      isCustomPaymentLabel:
        agency?.isCustomPaymentLabel === undefined
          ? false
          : agency?.isCustomPaymentLabel,
    },
    contractor: get_contractor(values),
    ...(values?.insured_list && { assets: get_assets(values?.insured_list) }),
    period: {
      type: get_period(values?.coverage_radios || ""),
      from: values?.coverage_start_date?.split("/").reverse().join("-"),
      to: values?.coverage_end_date?.split("/").reverse().join("-"),
    },
    sections: [],
    language: get_language().toLocaleUpperCase(),
    coverage: values?.coverage_type_radios,
    token: "token",
    family: values?.is_family,
    ...(values?.discount && { discount: values?.discount?.toUpperCase() }),
    ...(quote_id && { quoteId: quote_id }),
  };

  return data_;
};
