import { FormikErrors, FormikProvider } from "formik";
import moment from "moment";
import { DataEntryStep2Props } from "src/types";
import { FormikOtherInsured } from "src/types/formik";
import { Button } from "src/widgets/button/button";
import { InputCheckbox } from "src/widgets/input-checkbox/input-checkbox";
import { InputDate } from "src/widgets/input-date/input-date";
import { InputRadio } from "src/widgets/input-radio/input-radio";
import { InputSelect } from "src/widgets/input-select/input-select";
import { InputText } from "src/widgets/input-text/input-text";
import ScrollToTop from "src/widgets/scroll-to-top/scroll-to-top";
import { useDataEntryStep2 } from "./data-entry-step2.hook";
import PhoneInput from "react-phone-input-2";

export const DataEntryStep2 = ({
  formik,
  validationSchema,
  agency,
}: DataEntryStep2Props) => {
  const {
    t,
    lowerLanguage,
    nationalityOptions,
    fcQuestionOptions,
    genderOptions,
    radioOptions,
    closePickerTrigger,
    coverageStartDateDisabledFrom,
    coverageStartDateDisabledTo,
    coverageEndDateDisabledFrom,
    coverageEndDateDisabledTo,
    getStructures,
    toggleClosePickerTrigger,
    openInformativePersonalDataLink,
    onChangePhone,
  } = useDataEntryStep2(formik);

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
    setFieldTouched,
  } = formik;

  const Periods = (
    <div className="border border-1 rounded-3 p-3 ms-md-3 me-md-3 bg-ffffff mt-4">
      <div className="col-sm-12 p-0 px-md-3 pl-md-3 mb-2 mb-md-1 font-14">
        <div className="d-none d-md-block">
          <InputRadio
            name="coverage_radios"
            handleChange={(event: any) => {
              handleChange(event);
              setFieldValue(
                "coverage_start_date",
                moment().format("DD/MM/YYYY")
              );
              toggleClosePickerTrigger();
            }}
            handleBlur={handleBlur}
            options={radioOptions}
            validationSchema={validationSchema}
            touched={touched.coverage_radios}
            value={values.coverage_radios}
            error={errors.coverage_radios}
            vertically={false}
            label={t("data_entry_step_1.coverage_subtitle")}
            classes="m-0 p-0"
            classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
          />
        </div>

        <div className="d-block d-md-none">
          <InputRadio
            name="coverage_radios"
            handleChange={(event: any) => {
              handleChange(event);
              setFieldValue(
                "coverage_start_date",
                moment().format("DD/MM/YYYY")
              );
              toggleClosePickerTrigger();
            }}
            handleBlur={handleBlur}
            options={radioOptions}
            validationSchema={validationSchema}
            touched={touched.coverage_radios}
            value={values.coverage_radios}
            error={errors.coverage_radios}
            vertically={true}
            label={t("data_entry_step_1.coverage_subtitle")}
            classes="m-0 p-0"
            classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
          />
        </div>

        <div className="d-block d-md-flex">
          <div className="col-sm-12 col-md-6 p-0 pe-md-3 m-0">
            <InputDate
              name="coverage_start_date"
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              validationSchema={validationSchema}
              touched={touched.coverage_start_date}
              value={values.coverage_start_date}
              error={errors.coverage_start_date}
              label={t("data_entry_step_1.coverage_start_date")}
              placeholder={t(
                "data_entry_step_1.coverage_start_date_placeholder"
              )}
              disabled={values.coverage_radios === "id_seasonal"}
              disabledFrom={coverageStartDateDisabledFrom}
              disabledTo={coverageStartDateDisabledTo}
              classes="m-0 p-0"
              closePickerTrigger={closePickerTrigger}
            />
          </div>

          <div className="col-sm-12 col-md-6 p-0 ps-md-3 m-0">
            <InputDate
              name="coverage_end_date"
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              validationSchema={validationSchema}
              touched={touched.coverage_end_date}
              value={values.coverage_end_date}
              error={errors.coverage_end_date}
              label={t("data_entry_step_1.coverage_end_date")}
              placeholder={t("data_entry_step_1.coverage_end_date_placeholder")}
              disabled={values.coverage_radios !== "id_period"}
              disabledFrom={coverageEndDateDisabledFrom}
              disabledTo={coverageEndDateDisabledTo}
              classes="m-0 p-0"
              closePickerTrigger={closePickerTrigger}
            />
          </div>
        </div>

        {agency?.structures && agency?.structures?.length > 0 && (
          <div className="d-block d-md-flex">
            <div className="col-sm-12 col-md-6 p-0 pe-md-3 m-0 mt-2 font-15">
              <InputSelect
                name="convention"
                validationSchema={validationSchema}
                setFieldValue={setFieldValue}
                options={getStructures(agency?.structures)}
                touched={touched.convention}
                value={values.convention}
                error={errors.convention}
                label={t("data_entry_step_1.convention")}
                placeholder={t("data_entry_step_1.convention_placeholder")}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const FirstInsured = (
    <div className="border border-1 rounded-3 ps-3 pe-3 ms-md-3 me-md-3 bg-ffffff pb-3">
      <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 mb-2 mb-md-1 font-15-semibold mt-3">
        {t("data_entry_step_2.insured_subtitle")} 1
      </div>
      <div className="d-block d-md-flex">
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <InputText
            name="insured_name"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.insured_name}
            value={values.insured_name}
            error={errors.insured_name}
            label={t("data_entry_step_2.insured_name")}
            placeholder={t("data_entry_step_2.insured_name_placeholder")}
          />
        </div>
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <InputText
            name="insured_surname"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.insured_surname}
            value={values.insured_surname}
            error={errors.insured_surname}
            label={t("data_entry_step_2.insured_surname")}
            placeholder={t("data_entry_step_2.insured_surname_placeholder")}
          />
        </div>
      </div>

      <div className="d-block d-md-flex">
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <InputText
            name="insured_email"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.insured_email}
            value={values.insured_email}
            error={errors.insured_email}
            label={t("data_entry_step_2.insured_email")}
            placeholder={t("data_entry_step_2.insured_email_placeholder")}
          />
        </div>
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <InputText
            name="insured_email_confirm"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.insured_email_confirm}
            error={errors.insured_email_confirm}
            value={values.insured_email_confirm}
            label={t("data_entry_step_2.insured_email_confirm")}
            placeholder={t(
              "data_entry_step_2.insured_email_confirm_placeholder"
            )}
            onPaste={(event) => {
              event.preventDefault();
              return false;
            }}
            onCopy={(event) => {
              event.preventDefault();
              return false;
            }}
          />
        </div>
      </div>

      <div className="d-block d-md-flex">
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <label htmlFor="input-insured_phone" className="input-text--label d-block">
            {t('data_entry_step_2.insured_phone')}
          </label>
          <div
            className={errors.insured_phone?.formattedPhone ? 'phone-style-error' : 'phone-style'}
          >
            <PhoneInput
              country={lowerLanguage === 'it' ? 'it' : lowerLanguage === 'en' ? 'gb' : 'de'}
              placeholder={t('data_entry_step_2.insured_phone_placeholder')}
              value={values.insured_phone?.formattedPhone}
              onChange={onChangePhone}
            />
          </div>
          {errors.insured_phone?.formattedPhone && (
            <div className="font-14 c-862633">{errors.insured_phone?.formattedPhone}</div>
          )}
        </div>
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <InputText
            name="insured_cap"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.insured_cap}
            value={values.insured_cap}
            error={errors.insured_cap}
            label={t("data_entry_step_2.insured_cap")}
            placeholder={t("data_entry_step_2.insured_cap_placeholder")}
          />
        </div>
      </div>

      {/* <div className="d-block d-md-flex">
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <InputText
            name="insured_vat_number"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.insured_vat_number}
            value={
              values?.insured_vat_number?.toUpperCase() === undefined
                ? ''
                : values?.insured_vat_number?.toUpperCase()
            }
            error={errors.insured_vat_number}
            label={t('data_entry_step_2.insured_vat_number')}
            placeholder={t('data_entry_step_2.insured_vat_number_placeholder')}
          />
        </div>
      </div> */}

      <div className="d-block d-md-flex">
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <InputRadio
            name="insured_nationality"
            handleChange={handleChange}
            handleBlur={handleBlur}
            options={nationalityOptions}
            validationSchema={validationSchema}
            touched={touched.insured_nationality}
            value={values.insured_nationality}
            error={errors.insured_nationality}
            label={t("data_entry_step_2.insured_nationality")}
            classes="m-0 p-0"
            classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
          />
        </div>
      </div>

      <div className="d-block d-md-flex">
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <InputRadio
            name="insured_fc_question"
            handleChange={handleChange}
            handleBlur={handleBlur}
            options={fcQuestionOptions}
            validationSchema={validationSchema}
            touched={touched.insured_fc_question}
            value={values.insured_fc_question}
            error={
              errors.insured_fc_question && touched.insured_fc_question
                ? errors.insured_fc_question
                : ""
            }
            label={t("data_entry_step_2.insured_fc_question")}
            classesLabel="m-0 p-0 font-15 d-flex align-items-center mb-3"
            vertically={false}
            classes="m-0 p-0 mb-3"
          />
        </div>
      </div>

      {values.insured_fc_question === "yes_id" && (
        <div className="d-block d-md-flex">
          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputText
              name="insured_fc"
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={validationSchema}
              touched={touched.insured_fc}
              value={values.insured_fc?.toUpperCase()}
              error={errors.insured_fc}
              label={`${t("data_entry_step_2.insured_fc")}*`}
              placeholder={t("data_entry_step_2.insured_fc_placeholder")}
            />
          </div>
        </div>
      )}

      {values.insured_fc_question === "no_id" && (
        <div>
          <div className="d-block d-md-flex">
            <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
              <InputDate
                name="insured_birth"
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                validationSchema={validationSchema}
                touched={touched.insured_birth}
                value={values.insured_birth}
                error={errors.insured_birth}
                label={`${t("data_entry_step_2.insured_birth")}*`}
                placeholder={t("data_entry_step_2.insured_birth_placeholder")}
                disabledTo={new Date()}
              />
            </div>

            <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
              <InputText
                name="insured_birth_nation"
                handleChange={handleChange}
                handleBlur={handleBlur}
                validationSchema={validationSchema}
                touched={touched.insured_birth_nation}
                value={values.insured_birth_nation}
                error={errors.insured_birth_nation}
                label={`${t("data_entry_step_2.insured_birth_nation")}*`}
                placeholder={t(
                  "data_entry_step_2.insured_birth_nation_placeholder"
                )}
              />
            </div>
          </div>

          <div className="d-block d-md-flex">
            <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
              <InputText
                name="insured_birth_place"
                handleChange={handleChange}
                handleBlur={handleBlur}
                validationSchema={validationSchema}
                touched={touched.insured_birth_place}
                value={values.insured_birth_place}
                error={errors.insured_birth_place}
                label={`${t("data_entry_step_2.insured_birth_place")}*`}
                placeholder={t(
                  "data_entry_step_2.insured_birth_place_placeholder"
                )}
                classesError="font-15 m-0 c-862633"
                classesLabel="font-15"
              />
            </div>

            <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
              <InputRadio
                name="insured_gender"
                handleChange={handleChange}
                handleBlur={handleBlur}
                options={genderOptions}
                validationSchema={validationSchema}
                touched={touched.insured_gender}
                value={values.insured_gender}
                error={errors.insured_gender}
                label={`${t("data_entry_step_2.insured_gender")}*`}
                classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
                classes="m-0 p-0 mb-3"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const OtherInsured = (index: number) => {
    //TODO CHANGE ANY
    const insuredTouched = (touched.insured_list as any)?.[index];
    const insuredValue = values.insured_list?.[index];
    const insuredError = errors.insured_list?.[
      index
    ] as FormikErrors<FormikOtherInsured>;

    return (
      <div
        key={index}
        className="border border-1 rounded-3 p-3 ms-md-3 me-md-3 bg-ffffff mt-4"
      >
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 mb-2 mb-md-1 font-15-semibold">
          {t("data_entry_step_2.insured_subtitle")} {index + 2}
        </div>
        <div className="d-block d-md-flex">
          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputText
              name={`insured_list.${index}.name`}
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={
                validationSchema?.fields?.insured_list?.innerType
              }
              touched={insuredTouched?.name}
              error={insuredError?.name}
              value={insuredValue?.name}
              label={`${t("data_entry_step_2.insured_name")}*`}
              placeholder={t("data_entry_step_2.insured_name_placeholder")}
              classesError="font-15 m-0 c-862633"
              classesLabel="font-15"
            />
          </div>
          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputText
              name={`insured_list.${index}.surname`}
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={
                validationSchema?.fields?.insured_list?.innerType
              }
              touched={insuredTouched?.surname}
              value={insuredValue?.surname}
              error={insuredError?.surname}
              label={`${t("data_entry_step_2.insured_surname")}*`}
              placeholder={t("data_entry_step_2.insured_surname_placeholder")}
              classesError="font-15 m-0 c-862633"
              classesLabel="font-15"
            />
          </div>
        </div>

        <div className="d-block">
          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputRadio
              name={`insured_list.${index}.nationality`}
              handleChange={handleChange}
              handleBlur={handleBlur}
              options={nationalityOptions}
              validationSchema={
                validationSchema?.fields?.insured_list?.innerType
              }
              touched={insuredTouched?.nationality}
              value={insuredValue?.nationality}
              error={insuredError?.nationality}
              label={t("data_entry_step_2.insured_nationality")}
              classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
              classes="m-0 p-0"
            />
          </div>

          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputRadio
              name={`insured_list.${index}.fc_question`}
              handleChange={handleChange}
              handleBlur={handleBlur}
              options={fcQuestionOptions}
              validationSchema={
                validationSchema?.fields?.insured_list?.innerType
              }
              touched={insuredTouched?.fc_question}
              value={insuredValue?.fc_question}
              error={
                insuredError?.fc_question && insuredTouched?.fc_question
                  ? insuredError?.fc_question
                  : ""
              }
              label={`${t("data_entry_step_2.insured_fc_question")}*`}
              classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
              classes="m-0 p-0 mb-3"
            />
          </div>

          {values.insured_list?.[index]?.fc_question === "yes_id" && (
            <div className="d-block d-md-flex">
              <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                <InputText
                  name={`insured_list.${index}.fc`}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  validationSchema={validationSchema?.fields?.insured_list?.fc}
                  touched={insuredTouched?.fc}
                  value={insuredValue?.fc?.toUpperCase()}
                  error={insuredError?.fc}
                  label={`${t("data_entry_step_2.insured_fc")}*`}
                  placeholder={t("data_entry_step_2.insured_fc_placeholder")}
                  classesError="font-15 m-0 c-862633"
                  classesLabel="font-15"
                  classesField="text-upp-placeholder-low d-flex min-input-height"
                />
              </div>
            </div>
          )}

          {values.insured_list?.[index]?.fc_question === "no_id" && (
            <div>
              <div className="d-block d-md-flex">
                <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                  <InputDate
                    name={`insured_list.${index}.birth`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validationSchema={
                      validationSchema?.fields?.insured_list?.innerType
                    }
                    touched={insuredTouched?.birth}
                    value={insuredValue?.birth}
                    error={insuredError?.birth}
                    label={`${t("data_entry_step_2.insured_birth")}*`}
                    placeholder={t(
                      "data_entry_step_2.insured_birth_placeholder"
                    )}
                    disabledTo={new Date()}
                    classes="m-0 p-0"
                  />
                </div>
                <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                  <InputText
                    name={`insured_list.${index}.birth_nation`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    validationSchema={
                      validationSchema?.fields?.insured_list?.birth_nation
                    }
                    touched={insuredTouched?.birth_nation}
                    value={insuredValue?.birth_nation}
                    error={insuredError?.birth_nation}
                    label={`${t("data_entry_step_2.insured_birth_nation")}*`}
                    placeholder={t(
                      "data_entry_step_2.insured_birth_nation_placeholder"
                    )}
                  />
                </div>
              </div>
              <div className="d-block d-md-flex">
                <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                  <InputText
                    name={`insured_list.${index}.birth_place`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    validationSchema={
                      validationSchema?.fields?.insured_list?.birth_place
                    }
                    touched={insuredTouched?.birth_place}
                    value={insuredValue?.birth_place}
                    error={insuredError?.birth_place}
                    label={`${t("data_entry_step_2.insured_birth_place")}*`}
                    placeholder={t(
                      "data_entry_step_2.insured_birth_place_placeholder"
                    )}
                  />
                </div>

                <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                  <InputRadio
                    name={`insured_list.${index}.gender`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    options={genderOptions}
                    validationSchema={
                      validationSchema?.fields?.insured_list?.gender
                    }
                    touched={insuredTouched?.gender}
                    error={insuredError?.gender}
                    value={insuredValue?.gender}
                    label={`${t("data_entry_step_2.insured_gender")}*`}
                    classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
                    classes="m-0 p-0 mb-3"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const OtherInsureds = (
    <div>
      {[...Array(Number(values?.insured_number) - 1).keys()]?.map((_, index) =>
        OtherInsured(index)
      )}
    </div>
  );

  const Insureds = (
    <div className="mt-2">
      <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 mb-3 font-14-semibold text-uppercase">
        {t("data_entry_step_2.insured_title")}
      </div>
      {FirstInsured}
      {OtherInsureds}
    </div>
  );

  const InsuranceConsents = (
    <div className="mt-3">
      <div className="col-sm-12 p-0 px-md-3 pl-md-3 pr-md-3">
        <InputCheckbox
          name="consent_privacy"
          handleChange={handleChange}
          handleBlur={handleBlur}
          validationSchema={validationSchema}
          touched={touched.consent_privacy}
          value={values.consent_privacy}
          error={
            errors.consent_privacy && touched.consent_privacy
              ? errors.consent_privacy
              : ""
          }
          label={t("data_entry_step_2.consent_privacy_label")}
          classesLabel="m-0 font-16 d-flex align-items-baseline"
        />

        <div className="d-inline-block">
          <div className="py-md-2 font-15 display-contents">
            {t("data_entry_step_2.consent_privacy_text_1")}
          </div>
          <div className="py-md-2 display-contents c-891124 cursor-pointer">
            <Button
              flavor="transparentPrimary"
              text={t("data_entry_step_2.consent_privacy_link")}
              classes="p-0 display-contents"
              classesText="font-15 d-inline border-underline-link-862633"
              onPress={openInformativePersonalDataLink}
            />
          </div>
          <div className="py-md-2 font-15 display-contents">
            {t("data_entry_step_2.consent_privacy_text_2")}
          </div>
        </div>

        <InputCheckbox
          name="consent_marketing"
          handleChange={handleChange}
          handleBlur={handleBlur}
          validationSchema={validationSchema}
          touched={touched.consent_marketing}
          value={values.consent_marketing}
          error={errors.consent_marketing}
          label={t("data_entry_step_2.consent_marketing_label")}
          classesLabel="m-0 font-16 d-flex align-items-baseline mt-3"
        />

        <div className="d-inline-block">
          <div className="py-md-2 font-15 display-contents">
            {t("data_entry_step_2.consent_marketing_value")}
          </div>
          <div className="py-md-2 display-contents c-891124 cursor-pointer">
            <Button
              flavor="transparentPrimary"
              text={t("data_entry_step_2.consent_privacy_link_2")}
              classes="p-0 display-contents"
              classesText="font-15 d-inline border-underline-link-862633"
              onPress={openInformativePersonalDataLink}
            />
          </div>
          <div className="py-md-2 font-15 display-contents">
            {t("data_entry_step_2.consent_ending")}
          </div>
        </div>

        <div>
          <InputCheckbox
            name="consent_profiling"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.consent_profiling}
            value={values.consent_profiling}
            label={t("data_entry_step_2.consent_profiling_label")}
            classesLabel="m-0 font-16 d-flex align-items-baseline mt-3"
          />

          <div className="d-inline-block">
            <div className="py-md-2 font-15 display-contents">
              {t("data_entry_step_2.consent_profiling_value")}
            </div>
            <div className="py-md-2 display-contents c-891124 cursor-pointer">
              <Button
                flavor="transparentPrimary"
                text={t("data_entry_step_2.consent_privacy_link_2")}
                classes="p-0 display-contents"
                classesText="font-15 d-inline border-underline-link-862633"
                onPress={openInformativePersonalDataLink}
              />
            </div>
            <div className="py-md-2 font-15 display-contents">
              {t("data_entry_step_2.consent_ending")}
            </div>
          </div>
        </div>

        <div>
          <InputCheckbox
            name="consent_communication"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.consent_communication}
            value={values.consent_communication}
            label={t("data_entry_step_2.consent_communication_label")}
            classesLabel="m-0 font-16 d-flex align-items-baseline mt-3"
          />

          <div className="d-inline-block">
            <div className="py-md-2 font-15 display-contents">
              {t("data_entry_step_2.consent_communication_value")}
            </div>
            <div className="py-md-2 display-contents c-891124 cursor-pointer">
              <Button
                flavor="transparentPrimary"
                text={t("data_entry_step_2.consent_privacy_link_2")}
                classes="p-0 display-contents"
                classesText="font-15 d-inline border-underline-link-862633"
                onPress={openInformativePersonalDataLink}
              />
            </div>
            <div className="py-md-2 font-15 display-contents">
              {t("data_entry_step_2.consent_ending")}
            </div>
          </div>
        </div>

        <div className="pt-4 font-15-semibold">
          {t("commons.required_field")}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <FormikProvider value={formik}>
        <ScrollToTop />
        {Periods}
        {Insureds}
        {InsuranceConsents}
      </FormikProvider>
    </>
  );
};
