export const regex_date =
  /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;

export const regex_phone_number =
  /^(\+\d{1,2}\s)??\d{3}?[\s-]?\d{3}[\s-]?\d{0,9}$/;

export const regex_string = /^[a-zA-ZáàâãéèêíïòóôõöúçñùÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]{2,}$/;

export const regex_alphanumeric = /^[a-zA-Z0-9]{4}$/;

export const regex_fiscal_code =
  /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$/gim;

export const regex_birth_date =
  /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d$/;

export const regex_vat_number = /^(?=([A-Za-z]{2,4}))\1(?![\W_]+$)(?=.{2,12}$)[-_ 0-9]*(?:[a-zA-Z][-_ 0-9]*){0,2}$/
