import './Input.scss'

import React from 'react'
import { Image } from 'react-bootstrap'

import { valid_icon } from '../../assets'
import { InputPropsCustom } from '../../types/Input'
import { is_required } from '../../utils/commons'

const Input: React.FC<InputPropsCustom> = ({
  id,
  formik_props,
  formik_schema,
  touched,
  disabled,
  placeholder = "",
  textarea,
  label,
  error,
  warning,
  pattern = "",
  icon,
  icon_size = 36,
  icon_valid = valid_icon,
  type = "text",
  classes = "",
  classes_field = "d-flex min-input-height",
  classes_input = "no-border flex-1 px-3 py-2 w-100",
  classes_label = "m-0",
  classes_error = "m-0 c-862633",
  classes_warning = "m-0 c-ffbe00",
  classes_icon = "px-2 bg-ffffff",
  classes_icon_valid = "px-2 bg-ffffff",
  on_press_icon,
  on_paste,
  on_copy,
  on_change
}: InputPropsCustom) => {
  /**
   * Render functions
   */
  const render_label = () => {
    const label_ = is_required(formik_schema, id) ? `${label}*` : label;
    return (
      <label
        htmlFor={id}
        className={`
          ${classes_label}
          ${classes_label ? "d-block" : "d-none"}
          ${label ? "" : "opacity-0"}
        `}
      >
        {`${label_} `}
      </label>
    );
  };

  const render_input = () => {
    const props_ = {
      id,
      disabled,
      placeholder,
      type,
      pattern:pattern,
      className: `${classes_input} ${disabled ? "bg-e5e5e5" : ""}`,
      ...formik_props,
    };
    return textarea ? (
      <textarea {...props_} />
    ) : (
      <input
        data-testid="input"
        list={`${id}-datalist`}
        onPaste={on_paste}
        onCopy={on_copy}
        onChange={on_change}
        {...props_}
      />
    );
  };

  const render_field = () => (
    <div
      className={`
        ${classes_field}
        ${disabled ? "bg-e5e5e5" : ""}
        ${warning ? "border-warning" : ""}
        ${touched && error ? "border-error" : "border-normal"}
      `}
    >
      {/* Input */}
      {render_input()}

      {/* Generic icon */}
      {icon && (
        <Image
          src={icon}
          width={icon_size}
          className={classes_icon}
          onClick={on_press_icon}
          role={on_press_icon ? "button" : ""}
        />
      )}

      {/* Valid icon */}
      {icon !== "" && !error && formik_props?.value && (
        <Image
          src={icon_valid}
          width={icon_size}
          className={classes_icon_valid}
          onClick={on_press_icon}
        />
      )}
    </div>
  );

  const render_error = () => (
    <p
      className={`
        ${classes_error}
        ${classes_error ? "d-block" : "d-none"}
        ${touched && error ? "" : "opacity-0"}
      `}
    >
      {`${error} `}
    </p>
  );

  const render_warning = () => (
    <p className={classes_warning}>{`${warning} `}</p>
  );

  return (
    <main className={classes}>
      <div className="d-flex flex-column">
        {render_label()}
        {render_field()}
        {warning ? render_warning() : render_error()}
      </div>
    </main>
  );
};
export default Input;
