import { FormikDataEntryValues } from "src/types/formik";

export const getInitialValues: FormikDataEntryValues = {
  coverage_radios: "",
  coverage_type_radios: "1",
  coverage_start_date: "",
  coverage_end_date: "",
  insured_days: "daily",
  insured_daily: "daily_1",
  convention: "",
  insured_number: "1",
  insured_name: "",
  insured_surname: "",
  insured_email: "",
  insured_email_confirm: "",
  insured_phone: {
    country: {
      name: '',
      dialCode: '',
      countryCode: '',
      format: '',
    },
    formattedPhone: '',
    value: '',
  },
  insured_cap: "",
  insured_vat_number: "",
  insured_nationality: "italy_id",
  insured_fc_question: "",
  insured_birth: "",
  insured_birth_nation: "",
  insured_birth_place: "",
  insured_gender: "",
  insured_fc: "",
  consent_td: true,
  consent_privacy: false,
  consent_marketing: false,
  consent_profiling: false,
  consent_communication: false,
  insured_list: [],
  informative_vision: false,
  is_family: false,
  discount: "",
  registration_file: undefined,
};
